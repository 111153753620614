/* makes sizing simpler */

*,
*::before,
*::after
  box-sizing: border-box


/* remove default spacing */
/* force styling of type through styling, rather than elements */

* 
  margin: 0
  padding: 0
  // font: inherit

input
  border: 0px
/* dark mode user-agent-styles */

html
  // color-scheme: dark light

/* min body height */

body
  min-height: 100vh
