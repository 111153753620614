@import '../../theme'

.context-drawer-handle
	position: fixed
	right: 420px
	top: 30%
	display: flex
	align-items: center
	justify-content: center
	color: #ffffff
	width: 40px
	height: 40px
	font-size: 16px
	text-align: center
	// background: $primary-color
	border-radius: 4px 0 0 4px
	cursor: pointer
	pointer-events: auto
	transition: all 0.3s ease
