.account-map-table
  // max-width: none
  // width: 100%
  &.ant-form-item
    max-width: 270px
  .ant-table-small
    .ant-table-body
      margin: 0 !important
  &.ant-form-item
    max-width: 270px
.account-map-dash
  padding: 0 !important