@import "../../_theme"

.voucher-modal
  width: 100%
  max-width: 1800px

  .voucher-modal-wrapper
    display: flex
    height: 100%
    padding-top: 16px
    padding-bottom: 16px
    overflow-x: hidden
    
    .voucher-modal-menu
      border-right: 1px solid $border-color-base

    .voucher-modal-content
      width: calc(100% - 185px)
      min-height: 600px
      padding: 8px 24px
      background-color: rgba($primary-color, 0)

.virtual-table .ant-table-container:before,
  .virtual-table .ant-table-container:after 
    display: none
  
.virtual-table-cell
  box-sizing: border-box
  padding: 16px
  border-bottom: 1px solid #e8e8e8
  background: #FFF
  