@import './_theme'
@import './Reset'

body
  font-family: 'Manrope', sans-serif
  
.react-resizable
  position: relative
  background-clip: padding-box

.column-resizable-handle
  position: absolute
  right: -5px
  bottom: 0
  z-index: 1
  width: 10px
  height: 100%
  cursor: col-resize

@for $i from 1 through 10
  .ant-table-row-level-#{$i}
    background-color: darken(#f9f9f9, ($i - 1) * 2%)
    & .ant-table-cell-fix-left
      background-color: darken(#f9f9f9, ($i - 1)  * 2%)

.ant-statistic-content-value, .ant-statistic-content-value-int, .ant-statistic-content-prefix
  font-size: 20px

.hidden-column
  visibility: hidden
  white-space: nowrap
  // display: none

.ant-pro-fixed-header
  position: fixed !important

.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon
  opacity: 1

.ant-select-dropdown-menu-item-group-title
  overflow-x: hidden
  white-space: nowrap
  text-overflow: ellipsis

.hover-link
  cursor: pointer 
  &:hover
    color: $link-color
    
.ant-menu-item
  & a
    text-overflow: ellipsis
    overflow-x: hidden

table
  font-family: 'Noto Sans', sans-serif
  font-weight: 300
  letter-spacing: 0.2px
  th
    font-family: 'Manrope', sans-serif

.ant-table-small
  border: none

.explanation-row
  background-color: rgba(0, 0, 0, .04)

.title-explanation-row
  background-color: #f5f5f5

.dropping 
  border-bottom: 2px dashed $link-color !important

.dashboard-grid-list
  .ant-row
    display: grid
    gap: 16px !important
    grid-template-columns: repeat(auto-fill, minmax(calc(min(1200px, 100%)), 1fr))
    .ant-list-item
      margin: 0

.ant-pro-global-header
  & > div:nth-child(2)
    display: flex
    justify-content: space-between
    overflow: hidden
    white-space: nowrap

.page-header-wrapper
  border: 1px solid rgb(235, 237, 240)
  margin: -24px -24px 24px
  @media only screen and (max-width: 425px)
    margin: -24px -8px 12px

@media only screen and (max-width: 425px)
  .ant-page-header
    padding: 16px 8px

// Common mobile styles
@media only screen and (max-width: 425px)
  .ant-pro-basicLayout-content
    margin: 24px 8px !important
  .ant-card-body
    padding: 12px !important
  .settings-page-content
    padding: 24px 12px 0px 12px!important

