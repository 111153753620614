.comments {
  margin-top: 20px;
}

.comment {
  background: #f9f9f9;
  border: 1px solid #e1e1e1;
  padding: 3px;
  border-radius: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.comment-author {
  margin-bottom: 8px;
  font-size: 12px;
  color: #555;
}

.comment-author strong {
  font-size: 12px;
  color: #333;
}

.comment-text {
  font-size: 12px;
  color: #333;
  line-height: 1.6;
}
