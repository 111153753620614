@import "../../_theme"


.value-evaluation-main
  width: 100%
  height: 100%
  padding-top: 16px
  padding-bottom: 16px
  overflow-x: hidden
  background-color: white
  
  .value-evaluation-lower-part
    width: 100%
    padding: 24px
    
  .value-evaluation-top-part
    display: flex
    width: 100%
    height: 100%
    padding-top: 16px
    padding-bottom: 16px
    overflow-x: hidden
    background-color: white
    .value-evaluation-fiscal-years
      padding: 0px 24px
      border-right: 1px solid $border-color-base
      width: 600px
      & .ant-form-item
        margin-bottom: 1px
      & .ant-list-item-action
        margin: 0
      & .ant-list-split .ant-list-item
        padding: 1px 0px
      .company-search-list
        margin-top: 16px
        padding: 2px
        border-radius: 4px
        box-shadow: $box-shadow-base
        max-height: 300px
        overflow-y: auto

    .keyfigure-sub-heading
      color: rgba(0,0,0,.75)
      font-weight: bold
      display: block
      margin: 8px 0px 4px 0px

    .value-evaluation-graph
      width: calc( 100% - 600px)
      padding: 24px
    
    .ant-card
      box-shadow: $box-shadow-base

