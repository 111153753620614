

.cell-wrapper
  width: 100%
  height: 100%
  &:hover
    & .cell-action-button 
      display: block
      // flex-direction: column
      // align-items: center
      cursor: pointer
  .cell-action-button
    // flex-direction: column
    // align-items: center
    // display: none
    &.active
      display: block
      