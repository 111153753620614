@import '../../../_theme'

.dashboard-item-select
  .ant-row
    // margin-bottom: 16px
    .ant-col
      margin-bottom: 16px
      .ant-card
        height: 100%
        display: flex
        flex-direction: column
        .ant-card-body
          flex-grow: 1

.react-grid-item
  background-color: #fff
  box-shadow: $box-shadow-base

  .grid-item-header
    display: flex
    justify-content: center
    width: 100%
    height: 32px
    cursor: move
    border-bottom: 1px solid $border-color-base

// RGL Styles
.react-grid-layout
  position: relative
  transition: height 200ms ease
  &.animated
    & .react-grid-item
      &.cssTransforms
        transition-property: transform

  & .react-grid-item
    overflow: hidden
    transition: all 200ms ease
    transition-property: left, top

    &.cssTransforms
      transition-property: none

    &.resizing
      z-index: 1
      will-change: width, height

    &.react-draggable-dragging
      transition: none
      z-index: 3
      will-change: transform

    &.react-grid-placeholder
      background: #0225a1
      opacity: 0.2
      transition-duration: 100ms
      z-index: 2
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      -o-user-select: none
      user-select: none

  .react-resizable-handle
    position: absolute
    width: 20px
    height: 20px
    background-repeat: no-repeat
    background-origin: content-box
    box-sizing: border-box
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')
    background-position: bottom right
    padding: 0 3px 3px 0

    &-sw
      bottom: 0
      left: 0
      cursor: sw-resize
      transform: rotate(90deg)

    &-se
      bottom: 0
      right: 0
      cursor: se-resize

    &-nw
      top: 0
      left: 0
      cursor: nw-resize
      transform: rotate(180deg)

    &-ne
      top: 0
      right: 0
      cursor: ne-resize
      transform: rotate(270deg)

    &-w,

    &-e
      top: 50%
      margin-top: -10px
      cursor: ew-resize

    &-w
      left: 0
      transform: rotate(135deg)

    &-e
      right: 0
      transform: rotate(315deg)

    &-n,

    &-s
      left: 50%
      margin-left: -10px
      cursor: ns-resize

    &-n
      top: 0
      transform: rotate(225deg)

    &-s
      bottom: 0
      transform: rotate(45deg)
