.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}

:not(:root):fullscreen::backdrop {
  background: #ffffff;
}

pre code.hljs {
  padding: 0em;
  background: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

pre code.hljs::-webkit-scrollbar {
  display: none;
}
